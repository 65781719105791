.modal-content-wrapper {
    font-family: "Product Sans", sans-serif;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 70%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 10px;
    padding-left: 16px;
    outline: none;
    padding-right: 10px;
  }
  .vertical-line {
    position: absolute;
    left: 50px;
    top: 0;
    bottom: 10px;
    width: 4px;
    height: calc(100% - 100px);
    background-color: #3b7ced;
  }
  .numbered-circle {
    position: absolute;
    left: 22px;
  
    width: 60px;
    background-color: #e2e6e9;
    color: #3b7ced;
    font-weight: 600;
    font-size: 48px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-block: 16px solid white;
  }
  .numbered-circle-one {
    top: 90px;
  }
  .numbered-circle-two {
    bottom: 10px;
  }
  .btn-skip {
    position: absolute;
    top: 30px;
    right: 5%;
    z-index: 99;
    border-radius: 5px;
    text-transform: none;
    display: flex;
    padding: 16px 32px;
    background-color: #ffffff;
    border: 2px solid #7a8a98;
    cursor: pointer;
    transition: border ease-in-out 600ms;
  }
  .btn-skip:hover {
    border: 2px solid #09285d;
  }
  .modal-main-content {
    margin-left: 120px;
    padding-top: 70px;
  }
  .para-headline {
    color: #3b7ced;
    margin-bottom: 14px;
    text-align: left;
    font-size: 20px;
    font-weight: 600;
    line-height: 24.26px;
    font-family: "Product Sans";
  }
  .heading-text {
    font-weight: 900;
    font-size: 40px;
    text-align: left;
    color: #1a1a1a;
    margin-bottom: 4px;
    font-family: "Product Sans";
  }
  .para-description {
    font-family: "Product Sans";
    font-size: 14px;
    font-weight: 400;
    line-height: 16.98px;
    text-align: left;
    color: #7a8a98;
    margin-bottom: 40px;
  }
  
  .btn-goto-steps {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    padding: 16px 20px;
    background: #3bb1d2;
    box-shadow: 0px 32px 24px rgba(26, 26, 26, 0.08);
    border-radius: 4px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 72px;
    border: none;
    cursor: pointer;
    transition: background ease-in-out 500ms;
  }
  .btn-goto-steps:hover {
    background: #3bb1d2;
  }
  
  @media screen and (max-width: 53em) {
    .numbered-circle-two {
      bottom: 5px;
    }
    .modal-main-content {
      margin-left: 20%;
    }
    .btn-skip {
      padding: 8px 16px;
    }
    .heading-text {
      font-weight: 900;
      font-size: 28px;
      text-align: left;
      color: #1a1a1a;
      margin-bottom: 4px;
    }
    .para-description {
      font-family: Product Sans;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.98px;
      text-align: left;
      color: #7a8a98;
      margin-bottom: 20px;
    }
    .btn-goto-steps {
      padding: 12px 12px;
      background: #3b7ced;
      box-shadow: 0px 32px 24px rgba(26, 26, 26, 0.08);
      line-height: 20px;
      margin-bottom: 40px;
    }
  }
  
  @media screen and (max-width: 680px) {
    .modal-content-wrapper {
      width: 88%;
      padding-inline: 8px;
    }
    .vertical-line {
      left: 20px;
      top: 0;
      height: calc(100% - 80px);
    }
    .numbered-circle-one {
      top: 70px;
    }
    .numbered-circle-two {
      bottom: 20px;
    }
    .btn-skip {
      top: 8px;
      right: 8px;
    }
    .para-headline {
      margin-bottom: 8px;
      font-size: 18px;
      letter-spacing: 1px;
    }
    .heading-text {
      font-weight: 900;
      font-size: 20px;
      letter-spacing: 0.5px;
    }
    .btn-goto-steps {
      padding: 12px 12px;
      font-size: 16px;
    }
    .modal-main-content {
      margin-left: 50px;
      padding-top: 50px;
    }
    .numbered-circle {
      left: 8px;
      width: 30px;
      font-size: 24px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  
  @media screen and (width > 678px) and (width<1020px) and (height<= 600px) {
    .modal-content-wrapper {
      max-height: 340px;
      overflow-y: auto;
    }
  }
  