.registration-page {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  overflow-y: scroll;
  background-color: rgba(255, 255, 255, 1);
  background-image: url("../../src/assets/background.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 10px;
}
.form-header {
  width: 95%;
  height: 100%;
}

.form-header-details {
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px;
  margin-top: 5rem;
}
@media (max-width: 768px) {
  .form-header-details {
    margin-top: 30px !important;
  }
}
@media (max-width: 480px) {
  .form-header-details {
    margin-top: 30px !important;
  }
}
.form-header-activity {
  display: flex;
  justify-content: stretch;
  padding: 10px;
  align-items: left;
  gap: 20px;
  background-color: transparent;
  font-size: smaller;
  margin-left: 2px;
}
.form-header-activity h2 {
  font-size: 20px;
  margin-left: 5px;
}
@media (max-width: 768px) {
  .form-header-activity h2 {
    margin-left: -12px;
  }
}
@media (max-width: 480px) {
  .form-header-activity h2 {
    margin-left: -12px;
  }
}
/* Target the checkbox input directly */
.checkbox-group input[type="checkbox"] {
  transform: scale(1.5);
  -webkit-transform: scale(1.5); /* Safari support */
  margin-left: 30px;
}
@media (max-width: 768px) {
  .checkbox-group input[type="checkbox"] {
    margin-left: 70px;
    margin-top: -30px;
  }
}
/* Optional: style the label or icon next to the checkbox */
.checkbox-group .checkbox .icon {
  padding: 2px;
  font-size: 20px !important;
  height: 20px !important;
  line-height: 20px;
}

.header-text {
  font-size: 22px;
  letter-spacing: 1px;
  font-weight: 500;
  margin-left: 20px;
}

.autosave {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #92969a;
}

.newcompanybnav {
  border: 1.2px solid #e2e6e9;
  width: 25%;
  height: 40%;
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 4px;
}

.nr {
  width: 80%;
  height: 80%;
  color: gray;
}

.sep {
  width: 2%;
  height: 100%;
  border-left: 1px solid #e2e6e9;
}

.raprclk {
  width: 20%;
  display: flex;
  gap: 0.5rem;
}

.togclk {
  padding: 10px 20px;
  border-radius: 4px;
  border: 1px solid #e2e6e9;
  background-color: white;
  cursor: pointer;
}

/* Remove the spinner buttons from number input fields */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  -webkit-appearance: none;
  appearance: none;
}

/* registration style start here */
.registration-form {
  width: 100%;
  height: auto;
  padding: 40px;
  margin: 0 auto;
  background-color: #fff;
  border: 1px solid #e2e6e9;
  border-radius: 4px 0px 0px 0px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}
@media (max-width: 768px) {
  .registration-form {
    margin: 0;
  }
}
.registration-info {
  width: 95%;
  height: 100%;
}
/* ------------------- */
.registration-header-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  background-color: transparent;
  margin-bottom: 30px;
}
.registration-header-info h2 {
  color: #3b7ced;
  font-size: 25px;
  font-weight: 530;
}
.reg-action-btn {
  display: flex;
  align-items: center;
  gap: 20px;
}
.reg-action-btn button {
  padding: 12px 22px;
  font-size: 20px;
  font-weight: 510;
  background-color: #3b7ced;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: #fff;
}

.cancel-btn {
  background: transparent !important;
  color: #3b7ced !important;
}
/* form group */

.form-group {
  flex: 1;
  flex-grow: 1;
  display: grid;
  gap: 10px;
}

.form-group label {
  font-size: 20px;
  color: #0b0b0b;
  font-weight: 500;
}
@media (max-width: 768px) {
  .form-group label {
    font-size: 18px;
  }
}
@media (max-width: 480px) {
  .form-group label {
    font-size: 18px;
  }
}
.form-group input,
.form-group select {
  padding: 14px;
  border-radius: 4px;
  border: 1px solid #504e4e;
  width: 100%;
  /* max-width: 400px !important; */
  outline: none;
  transition: border-color 0.3s ease-in-out;
}

/* Basic Information Section */
.registration-basic-info {
  display: flex !important;
  align-items: center;
  margin-bottom: 40px;
  flex-wrap: wrap;
  gap: 60px;
}
@media (max-width: 768px) {
  .registration-basic-info {
    margin-bottom: 15px;
  }
}
@media (max-width: 480px) {
  .registration-basic-info {
    margin-bottom: 15px;
  }
}
.image-upload {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: #e2e6e9;
  cursor: pointer;
  margin-bottom: 10px;
}

.image-upload img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.image-preview {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.image-upload-text {
  color: #a0acb5;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}
.image-upload-text span {
  font-size: 10px;
  font-weight: 500;
}

/* Contact Information Section */
.registration-contact-info {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.registration-contact-info h2 {
  color: #3b7ced;
  font-size: 25px;
  font-weight: 530;
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}

.registration-contact-info-grouped {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  box-sizing: border-box;
  margin-bottom: 3.5% !important;
}
/* .registration-ro-grouped {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  box-sizing: border-box;
  margin-bottom: 3.5% !important;
  
} */
.registration-role-grouped .role-group {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  gap: 35px;
  flex-wrap: wrap;
  flex-grow: 1;
  justify-content: stretch;
}
.registration-role-grouped .role-group div {
  width: 100%;
  max-width: 400px;
}
.registration-role-grouped h2 {
  font-weight: 500;
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 25px;
  color: #3b7ced;
  font-weight: 530;
}
/* Flexbox for role-grouped container */
.registration-role-grouped .role-grouped {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.form-control {
  flex: 1;
  margin-right: 10px; /* Adds space between the input and button */
}

.add-role-button {
  display: flex;
  align-items: center;
}

.add-role-btn {
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  background: transparent;
}

.add-role-button .plus-icon {
  margin-right: 5px;
}

/* Style for the role list */
.role-list {
  margin-top: 10px;
  padding: 0;
  list-style-type: none;
}

.role-item {
  background-color: #f2f2f2;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  max-width: 400px;
  padding: 5px 10px;
  border-radius: 4px;
  margin-bottom: 5px;
}

.role-setup {
  margin-top: 30px;
  margin-bottom: 30px !important;
  font-size: 25px;
  color: #3b7ced;
  font-weight: 530;
}

.add-role-button {
  display: flex;
  align-items: center;
  background-color: transparent;
  color: #3366ff;
  padding: 8px 8px;
  width: 250px;
  /* border: 1px solid #92969a; */
  margin-top: 30px;
  font-size: 18px;
}

.add-role-button .plus-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.2rem;
  margin-right: 5px;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  cursor: pointer;
}
.settings {
  margin-top: 1rem;
  display: block;
  justify-content: stretch;
}
.settings button {
  padding: 16px 24px;
  font-size: 20px;
  font-weight: 900px !important;
  border: none;
  background-color: #fff;
  border: 2px solid #3b7ced;
  color: #3b7ced;
}
.settings .access-btn {
  border: 1.2px solid #ded6d6;
  color: #3b7ced;
}
#size {
  width: 32%;
}
/* on mobile submit */
#reg-action-btn {
  display: none !important;
}
/* Media Queries for Responsive Design */

/* For tablets */
@media (max-width: 768px) {
  .registration-page {
    width: 100%;
    height: 100%;
    background-color: #fff;
    background-image: none !important;
    /* margin-top: -100% !important; */
  }
  .registration-form {
    width: 100%;
    padding: 15px;
  }
  .registration-header-info {
    display: block;
    flex-direction: row;
    flex: 1;
    flex-wrap: wrap;
    gap: 25px;
    justify-content: flex-start;
  }
  .registration-basic-info {
    display: block !important;
    align-items: center;
    gap: 0;
  }
  .registration-header-info h2,
  .registration-contact-info h2 {
    font-size: 25px; /* Adjust font size for mobile */
  }
  .reg-action-btn {
    justify-content: flex-end;
    display: none;
  }

  #reg-action-btn {
    justify-content: space-between;
    display: flex !important;
    align-items: center;
    margin-top: -3%;
  }
  .reg-action-btn button {
    padding: 10px 18px; /* Adjust button padding for mobile */
    font-size: 18px;
  }
  .registration-role-grouped {
    display: block;
    align-items: center;
  }
  .registration-role-grouped .form-group {
    display: block;
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }

  .image-upload {
    width: 120px;
    height: 120px;
  }

  .form-group input,
  .form-group select {
    padding: 12px;
    width: 100% !important;
    flex: 0 0 calc(33.33% - 10px);
    gap: 20px;
    margin-bottom: 5%;
  }
  .settings {
    margin-left: 5px;
  }
  .settings button {
    padding: 10px;
  }
  #size {
    width: 100%;
  }
}

/* For mobile phones */
@media (max-width: 480px) {
  .registration-page {
    background-color: #fff !important;
    margin-top: 7rem !important;
  }
  .registration-header-info {
    display: block;
    flex-direction: row;
    flex: 1;
    flex-wrap: wrap;
    gap: 25px;
    justify-content: flex-start;
  }
  .header-text {
    font-weight: 400;
  }
  .registration-basic-info {
    display: block !important;
    align-items: center;
    gap: 0;
  }
  .registration-header-info h2,
  .registration-contact-info h2 {
    font-size: 25px; /* Adjust font size for mobile */
  }
  @media (max-width: 768px) {
    .registration-header-info h2,
    .registration-contact-info h2 {
      font-size: 20px; /* Adjust font size for mobile */
    }
  }
  @media (max-width: 480px) {
    .registration-header-info h2,
    .registration-contact-info h2 {
      font-size: 20px; /* Adjust font size for mobile */
    }
  }
  .reg-action-btn {
    justify-content: flex-end;
    display: none;
  }

  #reg-action-btn {
    justify-content: space-between;
    display: flex !important;
    align-items: center;
    margin-top: -5%;
    gap: 30px;
  }
  #reg-action-btn button {
    width: 100% !important;
    margin-bottom: 20px;
  }
  #reg-action-btn .cancel-btn {
    border: 2px solid #3366ff;
  }
  .reg-action-btn button {
    padding: 10px 18px; /* Adjust button padding for mobile */
    font-size: 18px;
  }
  .registration-role-grouped {
    display: block;
    align-items: center;
  }
  .registration-role-grouped .form-group {
    display: block;
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }
  .image-upload {
    width: 120px;
    height: 120px;
  }
  .registration-contact-info-grouped {
    display: block;
    align-items: center;
    margin-bottom: 3%;
  }
  .form-group input,
  .form-group select {
    padding: 12px;
    width: 100% !important;
    max-width: 100% !important;
    gap: 20px;
    margin-bottom: 5%;
  }
  #size {
    width: 100%;
  }
}
