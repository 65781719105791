.settings-page {
  background-image: url("/src/image/bg.svg");
  height: 100vh;
  width: 100%;
  background-size: contain;
  margin: 0;
  padding: 0;
  font-family: Product Sans;
}

.title {
  flex-grow: 1;
}

