.popup {
  position: absolute;
  /* height: 100vh; */
  /* width: 100%; */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.1);
  /* z-index: 10; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  overflow-y: auto;
  /* Style your popup */
  width: 90%;

  background: white;
  /* margin-top: 100px; */
  /* margin-bottom: 100px; */
  height: 70vh;
  border-radius: 20px;
}
.popup-heading {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  padding-inline: 52px;
  padding-block: 1rem;
}
.popup-heading > h3 {
  font-family: Product Sans;
  font-size: 24px;
  font-weight: 400;
  line-height: 29.11px;
  text-align: left;
}
.closeIcon {
  padding: 4px;
}
.closeIcon:hover {
  background-color: red;
}
.form-and-preview {
  display: flex;
  /* gap: 10px; */
  justify-content: space-between;
}

.popup-form {
  /* border: solid 1px; */
  padding: 52px;
  /* outline: solid purple 2px; */
}
.preview-section {
  padding-inline: 10px;
  padding-bottom: 20px;
  min-width: 294px;
  margin-block: 52px;
  margin-right: 52px;
  /* filter: drop-shadow(0px -4px 4px rgba(0, 0, 0, 0.1)); */
  box-shadow: 0px -5px 16px 8px rgba(0, 0, 0, 0.1);
  /* outline: solid seagreen; */
  border-radius: 5px;
}
.form-field {
  display: flex;
  gap: 30px;
  margin-bottom: 32px;
}
.field-desc {
  color: #1a1a1a;
  width: 132px;
  /* border: solid red; */
}

input[type="radio"] {
  margin-right: 10px;
  margin-bottom: 12px;
  cursor: pointer;
}
input[type="color"] {
  margin-right: 10px;
  border: none;
  outline: none;
  width: 20px;
  height: 20px;
  cursor: pointer;
  cursor: pointer;
}
.circle {
  width: 32px;
  height: 32px;
  border-radius: 3px;
  /* border: solid seagreen; */
}
.grey {
  background: #8c9aa6;
  margin-right: 12px;
}
.black {
  background: #000000;
}
.input-label {
  color: #7a8a98;
  cursor: pointer;
}
.form-field fieldset {
  border: none;
}
.ft-btn {
  padding: 16px 32px 16px 32px;
  gap: 10px;
  border-radius: 4px 0px 0px 0px;
  opacity: 0px;
  cursor: pointer;
}
.popup-actions {
  padding-block: 10px;
  padding-left: 50px;
  border-top: #1a1a1a solid 1px;
  display: flex;
  gap: 5px;
  /* background-color: green; */
  /* width: 100%; */
}

@media (max-width: 768px) {
  .popup-heading {
    padding-inline: 1rem;
    padding-block: 0.5rem;
  }
  .popup-heading > h3 {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 29.11px;
    text-align: left;
  }
  .closeIcon {
    padding: 4px;
    width: "16";
    height: "16";
  }
  .form-and-preview {
    flex-direction: column;
    gap: 16px;
    /* justify-content: space-between; */
  }
  .preview-section {
    min-width: 100%;
    /* filter: drop-shadow(0px -4px 4px rgba(0, 0, 0, 0.1)); */
    /* outline: solid seagreen; */
  }

  .ft-btn {
    cursor: pointer;
    padding: 8px 16px 8px 1px;
  }
}

@media print {
  .print-preview {
    /* Apply styles that will be used when printing */
  }
}
