.sidebar {
  position: fixed;
  top: 0;
  left: -120px;
  bottom: 0;
  width: 130px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  z-index: 1000;
  transition: all 0.3s ease;
  overflow-y: auto;
  /* gradient/d-blue */
  background: linear-gradient(180deg, #09285d 0%, #020a17 100%);
  scrollbar-width: thin;
}
.sidebar.open {
  left: 0;
}

.sidebar-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.sidebar-logo {
  width: 24px;
  height: 24px;
}

.sidebar-title {
  color: white;
  font-size: 1.2em;
  margin-left: 12px;
}

.sidebar-menu {
  flex-grow: 1;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
}

.sidebar-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  padding: 15px 16px;
  color: #b3b3b3;
  cursor: pointer;
  text-decoration: none; /* Remove default underline */
  margin-bottom: 8px;
  transition-duration: 300ms;
  transition-timing-function: ease-in;
}

.sidebar-item:hover,
.sidebar-icon:hover {
  background-color: #3b7ced;
  color: white;
}

.sidebar-item.focus,
.sidebar-icon.focus {
  background: #3b7ced; /* Active link color */
  color: white;
}
