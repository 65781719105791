.pending-boxes-container {
  display: flex;
  flex-wrap: wrap;
  gap: 28px;
  margin-bottom: 52px;
}

.form-wrapper {
  border: "solid 1px #E2E6E9";
}

.button-and-search-container {
  display: flex;
  gap: 40px;
  margin-bottom: 24px;
}
.new-incoming-product-btn {
  padding: 8px 24px 8px 24px;
  border-radius: 4px;
  background-color: #3b7ced;
  color: white;
  font-family: Product Sans;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  border: none;
}

.view-incoming-purchase-order-btn {
  padding: 8px 24px 8px 24px;
  border-radius: 4px;
  background-color: white;
  color: #3b7ced;
  font-family: Product Sans;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  border: none;
}

@media (width<425px) {
  .operation-wrapper {
    margin-inline: 16px;
  }
  .pending-boxes-container {
    gap: 16px;
  }
}
