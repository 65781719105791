.location-contain{
    width: 100%;
    height: 100%;
    font-family: Product Sans;
    display: block;
  }
  .create-location {
    background-color: #3b7ced;
    text-transform: capitalize;
    padding: 10px 20px;
    color: #fff;
    font-size: 18px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  .LocationOnIcon {
    font-size: 2rem !important;
    padding: 5px;
    background-color: #edeeef;
    color: #9cb9eb;
    border-radius: 50px;
  }