.card {
  font-family: "Product Sans";
  /* margin: 1rem 1rem 1rem 1rem; */
  padding: 1.5rem;
  /* width: 27%; */
  /* height: 30%; */
  background: #fff;
  box-shadow: 1px 1px 1px #fffafa;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  border: 1.2px solid #e2e6e9;
  gap: 8px;
  cursor: pointer;
  /* @media screen and (max-width: 1280px) and (min-width: 961px) {
    width: 25%;
  } */
  /* @media screen and (max-width: 960px) and (min-width: 527px) {
    width: 35%;
  } */
  /* @media screen and (max-width: 526px) and (min-width: 200px) {
    width: 60%;
  } */
}
.card:link,
.card:visited,
.card:active,
.card:hover {
  text-decoration: none;
  color: black;
}
.cardlist {
  /* display: flex; */
  /* flex-wrap: wrap; */
  /* align-items: center; */
  display: grid;
  column-gap: 48px;
  row-gap: 32px;
  transition: transform 0.1s ease;
  /* cursor: pointer; */
  margin-top: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  @media screen and (max-width: 1375px) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
  @media (max-width: 700px) and (min-width: 540px) {
    padding-inline: 10%;
  }
  /* :hover {
    transform: scale(1.05);
  } */
}
.card:hover {
  transform: scale(1.02);
}
/* .cardtext {
  margin-left: 0.5rem;
  :hover {
    transform: scale(1.02);
  }
} */
.cardhed {
  margin-bottom: 1.5rem;
  font-size: 16px;
  font-weight: 520;
  line-height: 19.41px;
  font-family: "Product Sans";
  color: black;
  /* text-align: left; */
}
.cardesc {
  font-size: 12px;
  color: #999999;
  line-height: 14.56px;
}
