.inven-header {
    width: 100%;
    height: 100%;
    font-family: Product Sans;
    display: block;
}
.location-form-wrapper {
    width: 100%;
    height: 100%;
    font-family: Product Sans;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    background-color: white;
    background-image: url("../../../assets/background.png");
    overflow: auto;
}

.location-form-container {
    width: 95%;
    height: 100%;
}

.location-form-header {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    margin-top: 3rem;
}

.location-header-left {
    display: flex;
    align-items: center;
    gap: 2rem;
    width: 50%;
    height: 100%;
}

.location-title {
    font-size: 1.5rem;
    font-weight: 500;
}

.location-autosave {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.location-header-right {
    display: flex;
    gap: 1.5rem;
    height: 100%;
    width: 20%;
    align-items: center;
    justify-content: end;
}

.location-pagination {
    border: 1.2px solid #e2e6e9;
    width: 25%;
    height: 40%;
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 4px;
}

.pagination-icon {
    width: 80%;
    height: 80%;
    color: gray;
}

.pagination-separator {
    width: 2%;
    height: 100%;
    border-left: 1px solid #e2e6e9;
}

.location-form-content {
    width: 100%;
    padding: 24px;
    border-radius: 4px;
    border: 1px solid #e2e6e9;
    background-color: white;
    display: flex;
}

.location-form-fields {
    width: 95%;
    height: 100%;
}

.location-form-info {
    width: 100%;
    height: 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #3b7ced;
    margin-top: -0.5rem;
}

.location-cancel-button {
    color: #3b7ced;
    border: none;
    padding: 10px;
    background: transparent;
}

.location-type-text {
    color: #3b7ced;
}

.location-manager-select,
.store-keeper-select {
    width: 100%;
    padding: 8px;
    border: 1px solid #e2e6e9;
    border-radius: 4px;
    font-size: 1rem;
}

.location-form-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 32px;
}

.add-location-button {
    padding: 10px 20px;
    background-color: #3b7ced;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
.swal-button-blue {
    background-color: #007bff !important;
    color: white !important;
}
