.forget-password-container {
  font-family: Product Sans;
  width: 100%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../image/background.png");
  background-size: cover;
  background-position: center;
}

.forget-password-form-wrapper {
  width: 100%;
  max-width: 480px;
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 16px 16px 64px 0px #1a1a1a29;
  padding: 32px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}

.forget-password-form {
  display: flex;
  flex-direction: column;
  gap: 14px;
  flex-grow: 1;
}

.form-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 8px;
  color: #1a1a1a;
}

.form-subtitle {
  font-size: 14px;
  color: #7a8a98;
  margin-bottom: 16px;
}

.form-label {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 8px;
  color: #1a1a1a;
}

.form-input {
  width: 100%;
  padding: 20px 24px;
  font-size: 14px;
  border: 1px solid #7a8a98;
  border-radius: 4px;
  margin-bottom: 12px;
}
.f-group {
  display: flex;
  flex-direction: column;
  gap: 18px;
}
.sub-group {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.form-input:focus {
  outline: none;
  border-color: #4285f4;
}
.company-name-example {
  font-size: 12px;
  color: #7a8a98;
  font-weight: 400;
}

.password-input-container {
  position: relative;
}

.toggle-password-button {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  color: #7a8a98;
}

.password-criteria {
  list-style-type: none;
  padding: 0;
  margin: 8px 0 12px;
}

.password-criteria li {
  font-size: 12px;
  color: #7a8a98;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
}

.password-criteria li::before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
  background-color: #ccc;
}

.password-criteria li.fulfilled::before {
  background-color: #34a853;
}

.submit-button {
  width: 100%;
  padding: 16px 24px;
  background-color: #3b7ced;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  margin-top: 16px;
  box-shadow: 16px 16px 64px 0px #1a1a1a29;
  transition: all ease-in-out 600ms;
}
.submit-button:hover {
  background-color: #09285d;
}

.login-link {
  text-align: left;
  margin-top: 16px;
  font-size: 14px;
}

.login-link a {
  color: #3b7ced;
  text-decoration: none;
  animation-timing-function: ease-out;
  animation-duration: 600ms;
}

.error-message {
  color: #d93025;
  font-size: 12px;
}

.api-error-message {
  background-color: #fce8e6;
  color: #d93025;
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 16px;
  font-size: 14px;
}

.confirmation-container {
  text-align: center;
  padding: 40px;
  border-radius: 16px;
  font-family: "Product Sans";
}

.confirmation-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 16px;
  color: #1a1a1a;
  text-align: left;
  letter-spacing: 1.1px;
}

.confirmation-message {
  font-size: 14px;
  color: #7a8a98;
  margin-bottom: 24px;
  font-family: "Product Sans";
  line-height: 16.98px;
  text-align: left;
}

.confirmation-button {
  padding: 12px 24px;
  background-color: #3b7ced;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  letter-spacing: 1.1px;
  font-weight: 520;
  cursor: pointer;
  width: 100%;
  box-shadow: 0px 32px 24px 0px #1a1a1a14;
  transition: all ease-in-out 600ms;
}

.confirmation-button:hover {
  background-color: #09285d;
}

@media (max-width: 768px) {
  .forget-password-form-wrapper {
    max-width: 90%;
  }

  .form-title {
    font-size: 20px;
  }

  .form-subtitle {
    font-size: 13px;
  }

  /* .form-label,
  .form-input,
  .password-input-container,
  .submit-button,
  .login-link {
    font-size: 12px;
  } */

  .f-group {
    display: flex;
    flex-direction: column;
    gap: 14px;
  }

  .submit-button {
    font-size: 14px;
  }
}

/* Media Query for Small Mobile Devices */
@media (max-width: 480px) {
  .forget-password-form-wrapper {
    padding: 16px;
    border-radius: 12px;
  }

  /* .form-title {
    font-size: 18px;
  } */

  /* .form-subtitle,
  .form-label,
  .form-input,
  .password-input-container,
  .submit-button {
    font-size: 12px;
    padding: 14px 18px;
  } */

  /* .submit-button {
    padding: 12px 18px;
    font-size: 14px;
  } */

  /* .password-criteria li {
    font-size: 11px;
  } */
}

@media (max-width: 360px) {
  .forget-password-form-wrapper {
    padding: 10px;
    border-radius: 10px;
  }

  .form-title {
    font-size: 16px;
  }

  /* .form-subtitle,
  .form-label,
  .form-input,
  .password-input-container,
  .submit-button {
    font-size: 11px;
    padding: 12px 16px;
  } */

  /* .submit-button {
    padding: 10px 16px;
    font-size: 13px;
  } */

  /* .password-criteria li {
    font-size: 10px;
  } */
}
