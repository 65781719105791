@font-face {
  font-family: "Product Sans";
  src: url("./font/ProductSansRegular.ttf");
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Product Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('../src/assets/background.png');
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.container-body {
  width: 100%;
  max-width: 1440px; /* Adjust as needed */
  margin: 0 auto; /* Centers the content */
  padding: 0; 
  /* margin-right: 4rem !important;
  margin-left: -2.8rem; */
}
@media screen and (max-width: 1024px) {
  .container-body {
    /* margin-top: 1.5rem !important;
    margin-right: 0 !important;
  margin-left: -1.5rem !important; */
  }
}
@media screen and (max-width: 768px) {
  .container-body{
    margin-top: 0 !important;
    margin-right: 0 !important;
  margin-left: 0 !important;
  }
}