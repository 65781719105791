
.image-figure {
  margin-top: 10px;
  width: 180px;
  height: 180px;
  border: none;
  background-color: #3b7ced;
  border-radius: 10px;
}
.reset-avatar {
  width: 100%;
  height: 200px;
  border-radius: 10px;
}

.name-email {
  display: flex;
  flex-direction: column;
  gap: 8px;
  /* border: solid 2px; */
}
.name-label {
  display: block;
  margin-bottom: 8px;
  font-family: Product Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.41px;
  text-align: left;
  color: #1a1a1a;
}

.email-input-wrapper,
.name-input-wrapper {
  display: flex;
  flex-direction: column;
  width: 400px;
}
.email-input,
.name-input {
  border: 1px solid #7a8a98;
  padding: 20px 24px 20px 24px;
  border-radius: 4px;
  flex: 1;
}
.email-input:focus,
.name-input:focus {
  outline: #3b7ced solid 1px;
  border: none;
}
@media (max-width: 768px) {
  .user-detail {
    margin-top: 20px;
    flex-direction: column;
    gap: 16px;
  }

  .email-input-wrapper,
  .name-input-wrapper {
    width: 100%;
  }
  .email-input,
  .name-input {
    flex: 1;
  }
}

/* Access right tab */
.access-right-tab {
  padding: 16px 24px;
  font-family: "Product Sans Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #a9b3bc;
  background: #ffffff;
  border: 1.2px solid #e2e6e9;
  border-radius: 4px 4px 0px 0px;
  cursor: pointer;
}
.access-right-tab:hover,
.access-right-tab:focus {
  color: #3b7ced;
  border: 1.2px solid #3b7ced;
}
.acitve {
  color: #3b7ced;
  border: 1.2px solid #3b7ced;
}
