.crfq {
  width: 100%;
  height: 100%;
  font-family: Product Sans;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  background-color: white;
  overflow: auto;
}
.fade-in {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.crfq1 {
  width: 95%;
  height: 100%;
}

.crfq2 {
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  gap: 30rem;
  margin-top: 3rem;
}

.crfq2a {
  display: flex;
  align-items: center;
  gap: 2rem;
  width: 50%;
  height: 100%;
}

.crfqhed {
  font-size: 1.5rem;
  font-weight: 500;
}

.crfqauto {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.crfq2b {
  display: flex;
  gap: 1.5rem;
  height: 100%;
  width: 20%;
  align-items: center;
  justify-content: end;
}

.crfqbpg {
  color: #a9b3bc;
}

.crfqbnav {
  border: 1.2px solid #e2e6e9;
  width: 25%;
  height: 40%;
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 4px;
}

.nr {
  width: 80%;
  height: 80%;
  color: gray;
}

.sep {
  width: 2%;
  height: 100%;
  border-left: 1px solid #e2e6e9;
}

.crfq3 {
  width: 100%;
  height: 79%;
  padding: 24px 0px 0px 0px;
  border-radius: 4px 0px 0px 0px;
  border: 1px solid #e2e6e9;
  background-color: white;
  display: flex;
  justify-content: center;
  margin-top: -0.5rem;
}

.crfqform {
  width: 95%;
  height: 100%;
}

.crfq3a {
  width: 100%;
  height: 10%;
}

.crfq3ae {
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #3b7ced;
}

.crfq3btn {
  background-color: #3b7ced;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
}
.crfq3b {
  width: 100%;
  height: 5%;
  display: flex;
  gap: 4rem;
  border-bottom: 1px solid #e2e6e9;
  margin-top: 1rem;
}

.crfq3ba {
  width: 5%;
  height: 90%;
  line-height: 1.5rem;
  margin-bottom: 1rem;
}

.crfq3bb {
  width: 15%;
  height: 90%;
  line-height: 1.5rem;
  margin-bottom: 1rem;
}
.crfq3bc {
  width: 15%;
  height: 90%;
  line-height: 1.5rem;
  margin-bottom: 1rem;
}

.crfq3c {
  width: 100%;
  height: 16.5%;
  display: flex;
  gap: 3rem;
  border-bottom: 1px solid #e2e6e9;
  margin: 1rem 0;
}

.crfq3ca {
  width: 25%;
  height: 90%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  line-height: 1.5rem;
  margin-bottom: 1rem;
}

/* New styles for table inputs */
.crfq3d table input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #e2e6e9;
  padding: 5px;
  background: none;
  outline: none;
}

.crfq3d table input:focus {
  outline: none;
  border-bottom: 1px solid #e2e6e9;
}
