.vendet {
  width: 100%;
  height: 100%;
  font-family: "Product Sans", sans-serif;
  overflow: auto;
  padding: 20px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  border: 1px solid #e2e6e9;
}
.vendet1 {
  width: 100%;
  height: 100%;
}
.vendet2 {
  width: 100%;
  height: 20%;
  color: #3b7ced;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.vendet2a {
  width: 40%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  justify-content: flex-end;
  /* background-color: red; */
}
.vendet2but {
  color: #3b7ced;
  border: none;
  padding: 10px;
  background: transparent;
}
.vendet2btn {
  background-color: #3b7ced;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  outline: none;
}
.vendet3 {
  width: 100%;
  height: 25%;
  display: flex;
  gap: 2rem;
  margin: 1.5rem 0;
}
.vendet3a {
  width: 15%;
  height: 90%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  line-height: 1.5rem;
}
.vendet3b {
  font-size: 14px;
  color: #7a8a98;
  margin-bottom: 1.5rem;
}

.vendet4 {
  width: 100%;
  height: 25%;
  display: flex;
  gap: 2rem;
  border-bottom: 1px solid #e2e6e9;
  margin: 1.5rem 0;
}
.vendet4a {
  width: 15%;
  height: 90%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  line-height: 1.5rem;}
.vendet4b {
  font-size: 14px;
  color: #7a8a98;
}
.editable-input {
  width: 95%;
  cursor: pointer;
  outline: none;
  border: 2px solid #e2e6e9;
  border-radius: 4px;
  padding: 10px;
}
.vendet5{
  margin: 1rem 0;
}