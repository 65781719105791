.login-container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('../image/background.png');
}

.login-form {
  width: 480px;
  height: 505px;
  background-color: #fff;
  padding: 40px;
  border-radius: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.login-title {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 8px;
  text-align: left;
}

.login-subtitle {
  font-size: 14px;
  color: #666;
  margin-bottom: 32px;
  text-align: left;
}

.form-group {
  margin-bottom: 24px;
}

.form-label {
  display: block;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 8px;
}

.form-input {
  width: 100%;
  
  padding: 20px 24px ;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.password-input-container {
  position: relative;
}

.password-toggle {
  position: absolute;
  right: 12px;
  transform: translateY(100%);
  background: none;
  border: none;
  cursor: pointer;
  color: #666;
}


.login-button {
  width: 100%;
  padding: 12px;
  background-color: #4285f4;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 22px;
}

.login-button:hover {
  background-color: #3367d6;
}

.login-links {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  font-size: 14px;
}

.register-link,
.forgot-password-link {
  color: #4285f4;
  text-decoration: none;
}

.error-message {
  color: #d32f2f;
  font-size: 12px;

}

.group-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

form {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

@media (max-width: 480px) {
  .login-form {
    width: 90%;
    height: auto;
    padding: 20px;
    border-radius: 8px;
    box-shadow: none;
    
  }

  .login-title {
    font-size: 22px;
    margin-bottom: 16px;
  }

  .login-subtitle {
    font-size: 12px;
    margin-bottom: 18px;
  }

  .form-group {
    margin-bottom: 10px;
  }

  .form-input {
    padding: 16px;
    font-size: 14px;
  }
  .password-toggle {
  position: absolute;
  right: 20px;
}

  .login-button {
    padding: 12px;
    font-size: 15px;
  }

  .login-links {
    flex-direction: column;
    align-items: center;
  }

  .register-link,
  .forgot-password-link {
    margin-bottom: 8px;
  }
}

/* Tablets (481px to 768px) */
@media (min-width: 481px) and (max-width: 768px) {
  .login-form {
    width: 80%;
    padding: 30px;
  }

  .login-title {
    font-size: 26px;
  }

  .login-subtitle {
    font-size: 13px;
  }

  .form-input {
    padding: 18px 22px;
    font-size: 13px;
  }

  .login-button {
    padding: 11px;
    font-size: 15px;
  }
}

/* Desktops (769px and above) */
@media (min-width: 769px) {
  .login-form {
    width: 480px;
    padding: 40px;
  }

  .login-title {
    font-size: 28px;
  }

  .login-subtitle {
    font-size: 14px;
  }

  .form-input {
    padding: 20px 24px;
    font-size: 14px;
  }

  .login-button {
    padding: 12px;
    font-size: 16px;
  }
}
