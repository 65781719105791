.reg-navbar {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../../image/background.png");
}
.reg-navbar4{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  align-items: center;
}
.reg-navbar-wrap {
  width: 85%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.reg-logo {
  width: 121.83px;
  height: 40px;
}

.reg-nav-links {
  width: 65%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.reg-nav-links.active {
  display: flex;
  position: absolute;
  top: 60px;
  height: 10%;
  width: 90%;
  right: 0;
  animation: reg-slideIn 2s forwards;
  z-index: 1;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
}

@media (max-width: 600px) {
  .reg-nav-links {
    display: none;
  }
  .reg-nav-links.active {
    display: flex;
  }
}

@keyframes reg-slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.reg-nav-items {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-left: -1rem;
}

.reg-nav-link {
  color: #000000;
  text-decoration: none;
}

.reg-nav-link:link, .reg-nav-link:visited {
  color: #000000;
  text-decoration: none;
}

.reg-nav-link:hover {
  color: #3b7ced;
  text-decoration: none;
}

.reg-dropdown {
  gap: 5px;
  padding: 0;
  position: relative;
  display: inline-block;
}

.reg-dropdown-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 10px;
}

.reg-dropdown-menu {
  display: none;
  position: absolute;
  min-width: 80px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 999;
}

.reg-dropdown-menu a {
  color: grey;
  padding: 8px 14px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.reg-dropdown-menu a:hover {
  color: #3b7ced;
}

.reg-dropdown:hover .reg-dropdown-menu {
  display: block;
}

.reg-dropdown:hover .reg-dropdown-toggle {
  color: #3b7ced;
}

.reg-contact-button {
  padding: 10px;
  color: #3b7ced;
  border: 2px solid #3b7ced;
  border-radius: 5px;
  text-decoration: none;
}

.reg-mobile-icon {
  display: none;
}

@media (max-width: 600px) {
  .reg-mobile-icon {
    display: block;
    position: absolute;
    right: 30px;
    color: #3b7ced;
  }
}
