.form-wrapper {
  margin-bottom: 200px;
  padding: 24px;
  background-color: white;
  border: solid 2px #e2e6e9;
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
.form-wrapper form {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.validate-btn {
  padding: 8px 24px 8px 24px;
  border-radius: 4px;
  border: solid 1px #3b7ced;
  color: #3b7ced;
  background-color: #fff;
  cursor: pointer;
}
.save-btn {
  padding: 8px 24px 8px 24px;
  border-radius: 4px;
  border: solid 1px #3b7ced;
  color: #ffffff;
  background-color: #3b7ced;
  cursor: pointer;
}
