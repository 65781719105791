.sub-heading {
  font-family: "Product Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #3b7ced;
}
.select-label {
  display: block;
  width: 200px;
  /* border: solid 2px; */
}

.select-control {
  display: inline-flex;
  gap: 32px;
  /* justify-content: stretch; */
  flex-direction: row;
  line-height: 2rem;
  /* border: red solid; */
  align-items: center;
}
.select {
  width: 1004px;
}

.checkbox-control {
  display: inline-flex;
  justify-content: space-between;
  /* gap: 165px; */
  max-width: 500px;
}

.input-checkbox {
  min-width: 15px;
  height: 22px;
  /* height: 22px; */
}

@media (max-width: 1300px) {
  .select {
    width: 500px;
  }
}
@media (max-width: 768px) {
  .select-control {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
  .select {
    width: 94vw;
  }
  .checkbox-control {
    display: inline-flex;
    gap: 40px;
  }
}
