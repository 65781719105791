.new-stock-adjustment {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  h2 {
    text-align: center;
  }
  
  .form-section {
    margin-bottom: 20px;
  }
  
  .field {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }
  
  .field label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .field input, .field select {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .product-table table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .product-table th, .product-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
  }
  
  .add-product {
    margin-top: 10px;
    background-color: #007bff;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .form-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .form-actions button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .form-actions button:first-of-type {
    background-color: #28a745;
    color: white;
  }
  
  .form-actions button:nth-of-type(2) {
    background-color: #007bff;
    color: white;
  }
  
  .form-actions button:last-of-type {
    background-color: #dc3545;
    color: white;
  }
  